import "react-toastify/dist/ReactToastify.css";
import "../../styles/main.scss";

import { ToastContainer } from "react-toastify";

import Verify from "../views/Verify";
import Footer from "./Footer";
import Header from "./Header";

function App() {
  return (
    <>
      <header>
        <Header />
      </header>
      <main>
        <Verify />
      </main>
      <footer>
        <Footer />
      </footer>
      <ToastContainer autoClose={30_000} theme="dark" />
    </>
  );
}

export default App;
