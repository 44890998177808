import { toast } from "react-toastify";
import verify from "../../fns/api/verify";
import env from "../../fns/common/env";
import injected from "../../fns/wallets/injected";
import walletConnect from "../../fns/wallets/walletConnect";
import { useChain, useWallet } from "../../hooks/useEventStates";

export default function Verify() {
  const chainId = useChain();
  const wallet = useWallet();

  const correctChain = chainId === env.chainId;

  return (
    <section className="mint-landing">
      <div className="container mx-auto text-center pt-10">
        <h1 className="text-5xl font-bold">For MetaFans NFT Holders ONLY!</h1>
        <h1 className="text-5xl font-bold">Get the best prices on tickets to your favorite events!</h1>
        <p className="text-gray-500 mb-5">We have a deep discount for all your favorite sporting events, concerts, and more. Connect your wallet below to take advantage of this awesome holder-only benefit!</p>
        <div className="mint-ctas flex flex-col sm:flex-row justify-center items-center">
          {!wallet ? (
            <div className="connect sm:mr-5">
              <button
                className="wallet"
                onClick={() => {
                  const connector = window.ethereum ? injected : walletConnect;

                  connector.connect().catch(err => toast.error(err.message));
                }}
              >Connect Wallet</button>
            </div>
          ) : !correctChain ? (
            <div className="connect sm:mr-5">
              <button
                className="wallet"
                onClick={() => {
                  const requestable = window.ethereum || global.walletConnectProvider;

                  if (!requestable) {
                    return;
                  }

                  requestable
                    .request({
                      method: "wallet_switchEthereumChain",
                      params: [{ chainId: `0x${env.chainId.toString(16)}` }],
                    })
                    .catch(err => toast.error(err.message));
                }}
              >Wrong Network</button>
            </div>
          ) : (
            <div className="connect sm:mr-5">
              <button
                className="wallet"
                onClick={() => {
                  verify
                    .post()
                    .then(() => window.location.replace(`/?_d=${new Date().valueOf()}`))
                    .catch(err => toast.error(err.message));
                }}
              >
                Verify
              </button>
            </div>
          )}
        </div>
        {/* <p className="text-gray-500 mt-5">Don't have a MetaFans NFT? Mint one today! And start taking advantage of this, plus many more benefits!</p>
        <div className="mint-ctas flex flex-col sm:flex-row justify-center items-center">
          <div className="learn">
            <a className="track-mint" href="/nft-utilities">Mint Now</a>
          </div>
        </div> */}
      </div>
    </section>
  );
}
