import { BigNumber } from "ethers";

import env from "../common/env";

/**
 * @param {number} chainId
 */
function getDomain(chainId) {
  return {
    name: "MetaFans",
    version: "1.0.0",
    chainId,
    verifyingContract: env.metafansAddress,
  };
}

const types = {
  Holder: [
    {
      name: "address",
      type: "address",
    },
    {
      name: "deadline",
      type: "uint256",
    },
  ],
};

const verify = {
  post: async function () {
    const deadline = BigNumber.from(Math.round(new Date().setDate(new Date().getDate() + 1).valueOf() / 1_000));

    if (!global.signer) {
      throw new Error("Signer disconnected");
    }

    const signature = await global.signer._signTypedData(getDomain(env.chainId), types, {
      address: global.wallet,
      deadline,
    });

    const res = await fetch("/api/verify", {
      method: "POST",
      body: JSON.stringify({
        address: global.wallet,
        deadline,
        signature,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    });

    if (res.status === 401) {
      throw new Error("Only NFT holders can access the tickets website");
    }

    if (res.status >= 400) {
      throw new Error("Unexpected error. Please try again, or contact support if this keeps happening.");
    }
  },
};

export default verify;
