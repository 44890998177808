import { providers } from "ethers";

import dispatchEvent from "../common/dispatchEvent";

const signerObserver = {
  process: function () {
    const getNewSigner = () => {
      if (!window.ethereum) {
        return;
      }

      dispatchEvent("signerChanged", new providers.Web3Provider(window.ethereum).getSigner());
    };

    window.addEventListener("chainChanged", getNewSigner);
    window.addEventListener("walletChanged", getNewSigner);
  },
};

export default signerObserver;
