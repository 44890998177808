import { useState } from "react";

import useEvent from "./useEvent";

/**
 *
 * @template {keyof CustomEventMap} T
 * @param {T} eventName
 * @param {CustomEventMap[T]} initValue
 */
export default function useEventState(eventName, initValue) {
  const [value, setValue] = useState(initValue);

  useEvent(eventName, setValue, [setValue]);

  return value;
}
