import React from 'react';

import discord from "../../assets/discord.svg";
import instagram from "../../assets/instagram.svg";
import twitter from "../../assets/twitter.svg";

export default function Footer() {
  return (
    <section className="footer">
      <div className="grid grid-cols-1 lg:grid-cols-2 footer-container">
        <div className="col-span-1 order-2 lg:order-1">
          <div className="footer-nav flex flex-col lg:flex-row items-center">
            <a href="https://www.metafans.com/">Home</a>
            <a href="https://www.metafans.com/who-we-are">Who We Are</a>
            <a href="https://www.metafans.com/nft-utilities">NFT & Utilites</a>
            <a href="#">Tickets</a>
          </div>
        </div>
        <div className="col-span-1 order-1 lg:order-2">
          <div className="flex flex-col lg:flex-row lg:justify-end">
            <div className="footer-icons flex flex-row items-center justify-center lg:justify-start order-2 lg:order-1 mt-5 lg:mt-0 mr-0 lg:mr-3">
              <div>
                <a href="https://discord.com/invite/5RrNCnvRny" target="_blank" className="discord">
                  <img className="w-7" src={discord} alt="discord link"></img>
                </a>
              </div>
              <div>
                <a href="https://twitter.com/metafansnft" target="_blank" className="twitter">
                  <img className="w-7" src={twitter} alt="twitter link"></img>
                </a>
              </div>
              <div>
                <a href="https://www.instagram.com/metafansnft/" target="_blank" className="insta">
                  <img className="w-7" src={instagram} alt="instagram link"></img>
                </a>
              </div>
            </div>
            <div className="order-1 lg:order-2 text-center">
              {/* <PrimaryActionButton /> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-copyright flex items-center justify-between">
        <div>
          <p className="text-gray-500">© 2022 MetaFans</p>
        </div>
        <div className="flex items-center justify-end mini-nav">
          <div>
            <a href="https://www.metafans.com/privacy-policy">
              <p className="text-gray-500 mb-0">Privacy policy</p>
            </a>
          </div>
          <div>
            <a href="https://www.metafans.com/terms">
              <p className="text-gray-500 mb-0">Terms of services</p>
            </a>
          </div>
          <div>
            <a href="https://www.metafans.com/affiliate">
              <p className="text-gray-500 mb-0">Affiliate program</p>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
