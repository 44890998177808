/**
 * @param {string} key
 */
function getValue(key) {
  const reactEnv = process.env.REACT_APP_ENV?.toUpperCase() ?? "LOCAL";

  const compKey = `REACT_APP_${reactEnv}_${key}`;

  const value = process.env[compKey];

  if (!value) {
    console.error(`Missing key: ${compKey}`);
  }

  return value;
}

const env = {
  chainId: Number(getValue("CHAIN_ID")),
  infuraKey: getValue("INFURA_KEY"),
  metafansAddress: getValue("METAFANS_ADDRESS"),
};

global.env = env;

export default env;
