import React from "react";
import ReactDOM from "react-dom";

import App from "./comps/shell/App";
import globalObserver from "./fns/observers/globalObserver";
import signerObserver from "./fns/observers/signerObserver";

globalObserver.process();
signerObserver.process();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root"),
);
