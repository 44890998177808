import React, { useState } from 'react';

import logo from '../../assets/logo.svg';

export default function Header() {
  const [open, setOpen] = useState(false);

  return (
    <div className="m-5 mt-2 header">
      <div className="flex justify-start lg:justify-between items-center">
        <div className="w-1/3">
          <nav className="navbar">
            <div onClick={() => setOpen(!open)} className="nav-icon">
              {/* {open ? <FiX /> : <img src={bugger} alt="hamburger"/>} */}
            </div>
            <ul className={open ? 'nav-links active' : 'nav-links'}>
              <li className="nav-item">
                <a
                  href="https://www.metafans.com/"
                  className="nav-link"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.metafans.com/who-we-are"
                  className="nav-link"
                >
                  Who We Are
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.metafans.com/nft-utilities"
                  className="nav-link"
                >
                  NFT & Utilites
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="#"
                  className="nav-active"
                >
                  Tickets
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div className="w-1/3 flex justify-center">
          <a href="https://www.metafans.com/">
            <img src={logo} className="logo" alt="metafans logo" />
          </a>
        </div>
        <div className="w-1/3 lg:hidden"></div>
        <div className="hidden lg:flex w-1/3 flex-col-reverse md:flex-row justify-center md:justify-end items-center">
          {/* <PrimaryActionButton /> */}
        </div>
      </div>
    </div>
  );
}
