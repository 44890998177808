const globalObserver = {
  process: function () {
    window.addEventListener("chainChanged", ev => (global.chainId = ev.detail));
    window.addEventListener("providerChanged", ev => (global.provider = ev.detail));
    window.addEventListener("signerChanged", ev => (global.signer = ev.detail));
    window.addEventListener("walletChanged", ev => (global.wallet = ev.detail));
  },
};

export default globalObserver;
