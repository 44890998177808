import { utils } from "ethers";

const walletUtils = {
  /**
   * @param {string | undefined} walletish
   */
  canon: async function (walletish) {
    if (!walletish) {
      return walletish;
    }

    let builder;

    if (walletish.endsWith("eth")) {
      if (!global.provider) {
        throw new Error("Need to resolve ENS, but no provider is available");
      }

      builder = await global.provider.resolveName(walletish);
    }

    if (!builder) {
      builder = walletish;
    }

    return utils.getAddress(builder);
  },
};

export default walletUtils;
